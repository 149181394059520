import ApiResource, { ApiQuery, IModel } from "@/resources/ApiResource";
import useAxios from "@/composables/use-axios";

const { apiClient } = useAxios();

class ProductResource extends ApiResource {
  async exportToExcel(query: ApiQuery) {
    return apiClient()
      .get(`${this.uri}/export`, { params: query })
      .then((response) => {
        const apiBaseUrl = process.env.VUE_APP_API_URL.replace("/api", "/");
        const filePath = `${apiBaseUrl}/${response.data.filePath}`;

        window.location.replace(filePath);
      });
  }
}

export default new ProductResource("/product");

export interface IProductModel extends IModel {
  id: number;
  sku: string;
  name: string;
  is_consumable: boolean;
}
