
import { defineComponent, onMounted, ref, reactive, toRefs } from 'vue';
import CategoryResource, {ICategoryModel} from '@/resources/CategoryResource';
import ProductResource, {IProductModel} from '@/resources/ProductResource';
import ProductReservationResource from '@/resources/ProductReservationResource';
import Pagination from '@/components/Pagination/Pagination.vue';
import TableToolbar from '@/components/TableToolbar.vue';
import Thumbnail from '@/components/Product/Category/Thumbnail.vue';
import Form from '@/components/ProductReservation/Form.vue';
import Filters from '@/components/Catalog/Filters.vue';
import TableFilters from '@/components/TableFilters.vue';
import ActiveEventSwitcher from '@/components/Event/ActiveEventSwitcher.vue';
import useList from '@/composables/use-list';
import useActiveEvent from '@/composables/use-active-event';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { TableFilters, Filters, ActiveEventSwitcher, Form, TableToolbar, Pagination, Thumbnail },
    setup() {
        const filters = reactive({
            keyword: undefined,
        });
        const { state, fetch, pagination } = useList(CategoryResource);
        const list = toRefs(state);
        const categoryProducts: Map<number, IProductModel> = reactive(new Map());
        const isModalOpen = ref(false);
        const reservation = reactive({
            event_id: 0,
            user_id: 0,
            product_id: 0,
            reserved_qty: undefined,
            reserved_from: '',
            reserved_to: '',
            comment: '',
        });

        const form = useForm(reservation, ProductReservationResource);
        const { save  } = form;

        function toggleCategoryProducts(category: ICategoryModel) {
            if (categoryProducts.get(category.id)) {
                categoryProducts.delete(category.id);
            } else {
                ProductResource.query({ expand: 'inventory,media', filters: { category_ids: category.id } }).then((response) => {
                    categoryProducts.set(category.id, response.data.data);
                });
            }
        }

        function openModal(product: IProductModel) {
            isModalOpen.value = true;

            reservation.product_id = product.id;
            reservation.reserved_qty = undefined;
            reservation.comment = '';

            const { activeEvent } = useActiveEvent();

            if (activeEvent.value) {
                reservation.event_id = activeEvent.value.id;
                reservation.reserved_from = activeEvent.value.start_date;
                reservation.reserved_to = activeEvent.value.end_date;
            }

            window.setTimeout(() => document.getElementById('reserved_qty')?.focus(), 100);
        }

        function closeModal() {
            isModalOpen.value = false;
        }

        function submitForm() {
            save().then(() => {
                closeModal();
            });
        }

        function submitFilters() {
            fetch({ page: 1, filters });
        }

        function clearFilters() {
            Object.assign(filters, {
                keyword: undefined,
            });

            fetch();
        }

        onMounted(() => {
            fetch();
        });

        return {
            isModalOpen,
            openModal,
            closeModal,
            form,
            submitForm,
            filters,
            clearFilters,
            submitFilters,
            toggleCategoryProducts,
            categoryProducts,
            items: list.items,
            pagination
        };
    },
});
