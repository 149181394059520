import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openFull && _ctx.openFull(...args)), ["prevent"]))
  }, [
    _createElementVNode("img", {
      src: _ctx.image.thumbnailUrl,
      alt: "",
      height: "64"
    }, null, 8, _hoisted_1)
  ]))
}