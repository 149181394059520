import { reactive, toRefs } from 'vue';
import { IEventModel } from '@/resources/EventResource';

export default function useActiveEvent() {
    const activeEvent = window.localStorage.getItem('active-event');
    const state = reactive({
        activeEvent: <IEventModel|null>null
    });

    if (activeEvent) {
        state.activeEvent = JSON.parse(activeEvent);
    }

    const setActiveEvent = (event: IEventModel): void => {
        state.activeEvent = event;

        window.localStorage.setItem('active-event', JSON.stringify(event));
    };

    return {
        ...toRefs(state),
        setActiveEvent
    };
};
