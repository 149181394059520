
import { defineComponent, PropType, reactive } from 'vue';
import * as basicLightbox from 'basiclightbox';
import { IProductMediaModel } from '@/resources/MediaResource';

export default defineComponent({
    props: {
        media: {
            type: Object as PropType<IProductMediaModel[]>,
            required: true
        }
    },

    setup(props) {
        const image = reactive({
            fullUrl: 'https://via.placeholder.com/640',
            thumbnailUrl: 'https://via.placeholder.com/64'
        });

        if (props.media.length > 0) {
            const firstImage = Array.from(props.media)[0];

            image.fullUrl = firstImage.url;
            image.thumbnailUrl = firstImage.conversions['category-thumb'].url;
        }

        function openFull(): void {
            basicLightbox.create(`<img src="${image.fullUrl}" />`).show();
        }

        return {
            image,
            openFull
        }
    }
});
