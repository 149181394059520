
import { defineComponent, onMounted, ref } from 'vue';
import EventResource from '@/resources/EventResource';
import useActiveEvent from '@/composables/use-active-event';

export default defineComponent({
    setup() {
        const eventOptions = ref([]);
        const { activeEvent, setActiveEvent } = useActiveEvent();

        onMounted(() => {
            EventResource.query({ filters: { is_archived: 0 } }).then((response) => {
                eventOptions.value = response.data.data;
            });
        });

        return {
            eventOptions,
            activeEvent,
            setActiveEvent,
        }
    }
});
