import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message is-danger"
}
const _hoisted_2 = { class: "message-body" }
const _hoisted_3 = { class: "field is-horizontal" }
const _hoisted_4 = { class: "field-label is-normal" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "field-body" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_10 = { class: "field is-horizontal" }
const _hoisted_11 = { class: "field-label is-normal" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "field-body" }
const _hoisted_14 = { class: "field" }
const _hoisted_15 = { class: "control" }
const _hoisted_16 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_17 = {
  key: 1,
  class: "field is-horizontal"
}
const _hoisted_18 = { class: "field-label is-normal" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "field-body" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = { class: "control" }
const _hoisted_23 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_24 = { class: "field is-horizontal" }
const _hoisted_25 = { class: "field-label is-normal" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "field-body" }
const _hoisted_28 = { class: "field" }
const _hoisted_29 = { class: "control" }
const _hoisted_30 = ["value"]
const _hoisted_31 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_32 = { class: "field is-horizontal" }
const _hoisted_33 = { class: "field-label is-normal" }
const _hoisted_34 = { class: "label" }
const _hoisted_35 = { class: "field-body" }
const _hoisted_36 = { class: "field" }
const _hoisted_37 = { class: "control" }
const _hoisted_38 = ["value"]
const _hoisted_39 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_40 = { class: "field is-horizontal" }
const _hoisted_41 = { class: "field-label is-normal" }
const _hoisted_42 = { class: "label" }
const _hoisted_43 = { class: "field-body" }
const _hoisted_44 = { class: "field" }
const _hoisted_45 = { class: "control" }
const _hoisted_46 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_47 = { class: "field is-horizontal" }
const _hoisted_48 = { class: "field-label is-normal" }
const _hoisted_49 = { class: "label" }
const _hoisted_50 = { class: "field-body" }
const _hoisted_51 = { class: "field" }
const _hoisted_52 = { class: "control" }
const _hoisted_53 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errors.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errors.message), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("event.EVENT_NAME")) + " *", 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Multiselect, {
              class: _normalizeClass(["", { 'is-danger': _ctx.errors.event_id }]),
              modelValue: _ctx.model.event_id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.event_id) = $event)),
              mode: "single",
              "value-prop": "id",
              label: "name",
              searchable: true,
              "track-by": "name",
              options: _ctx.eventOptions,
              onSelect: _ctx.onEventSelect
            }, null, 8, ["modelValue", "class", "options", "onSelect"])
          ]),
          (_ctx.errors.event_id)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.errors.event_id), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("product.PRODUCT_NAME")) + " *", 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Multiselect, {
              class: _normalizeClass(["", { 'is-danger': _ctx.errors.product_id }]),
              modelValue: _ctx.model.product_id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.product_id) = $event)),
              mode: "single",
              "value-prop": "id",
              label: "name",
              searchable: true,
              "track-by": "name",
              options: _ctx.productOptions
            }, null, 8, ["modelValue", "class", "options"])
          ]),
          (_ctx.errors.product_id)
            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.errors.product_id), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.loggedInUser.role.id === _ctx.UserRoleEnum.ROLE_ADMIN)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("user.USER_NAME")) + " *", 1)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_Multiselect, {
                  class: _normalizeClass(["", { 'is-danger': _ctx.errors.user_id }]),
                  modelValue: _ctx.model.user_id,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.user_id) = $event)),
                  mode: "single",
                  "value-prop": "id",
                  label: "name",
                  searchable: true,
                  "track-by": "name",
                  options: _ctx.userOptions
                }, null, 8, ["modelValue", "class", "options"])
              ]),
              (_ctx.errors.user_id)
                ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.errors.user_id), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("product_reservation.RESERVED_FROM")) + " *", 1)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_DatePicker, {
              modelValue: _ctx.model.reserved_from,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.reserved_from) = $event)),
              "model-config": { type: 'string', mask: 'DD.MM.YYYY' },
              masks: { input: 'DD.MM.YYYY' },
              popover: { visibility: 'click' },
              "first-day-of-week": 2
            }, {
              default: _withCtx(({ inputValue, inputEvents }) => [
                _createElementVNode("input", _mergeProps({
                  class: ["input", { 'is-danger': _ctx.errors.reserved_from }],
                  value: inputValue
                }, _toHandlers(inputEvents)), null, 16, _hoisted_30)
              ]),
              _: 1
            }, 8, ["modelValue", "model-config", "masks"])
          ]),
          (_ctx.errors.reserved_from)
            ? (_openBlock(), _createElementBlock("p", _hoisted_31, _toDisplayString(_ctx.errors.reserved_from), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("product_reservation.RESERVED_TO")) + " *", 1)
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createVNode(_component_DatePicker, {
              modelValue: _ctx.model.reserved_to,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.reserved_to) = $event)),
              "model-config": { type: 'string', mask: 'DD.MM.YYYY' },
              masks: { input: 'DD.MM.YYYY' },
              popover: { visibility: 'click' },
              "first-day-of-week": 2
            }, {
              default: _withCtx(({ inputValue, inputEvents }) => [
                _createElementVNode("input", _mergeProps({
                  class: ["input", { 'is-danger': _ctx.errors.reserved_to }],
                  value: inputValue
                }, _toHandlers(inputEvents)), null, 16, _hoisted_38)
              ]),
              _: 1
            }, 8, ["modelValue", "model-config", "masks"])
          ]),
          (_ctx.errors.reserved_to)
            ? (_openBlock(), _createElementBlock("p", _hoisted_39, _toDisplayString(_ctx.errors.reserved_to), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_40, [
      _createElementVNode("div", _hoisted_41, [
        _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t("product_reservation.RESERVED_QTY")) + " *", 1)
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              id: "reserved_qty",
              class: _normalizeClass(["input", { 'is-danger': _ctx.errors.reserved_qty }]),
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.reserved_qty) = $event))
            }, null, 2), [
              [_vModelText, _ctx.model.reserved_qty]
            ])
          ]),
          (_ctx.errors.reserved_qty)
            ? (_openBlock(), _createElementBlock("p", _hoisted_46, _toDisplayString(_ctx.errors.reserved_qty), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_47, [
      _createElementVNode("div", _hoisted_48, [
        _createElementVNode("label", _hoisted_49, _toDisplayString(_ctx.$t("product_reservation.COMMENT")), 1)
      ]),
      _createElementVNode("div", _hoisted_50, [
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _withDirectives(_createElementVNode("textarea", {
              class: _normalizeClass(["textarea", { 'is-danger': _ctx.errors.comment }]),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.comment) = $event))
            }, null, 2), [
              [_vModelText, _ctx.model.comment]
            ])
          ]),
          (_ctx.errors.comment)
            ? (_openBlock(), _createElementBlock("p", _hoisted_53, _toDisplayString(_ctx.errors.comment), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}