
import { defineComponent, ref, toRefs } from "vue";
import Multiselect from "@vueform/multiselect";
import { DatePicker } from "v-calendar";
import { find } from "lodash";
import EventResource from "@/resources/EventResource";
import ProductResource from "@/resources/ProductResource";
import UserResource from "@/resources/UserResource";
import useLoggedInUser from "@/composables/use-logged-in-user";
import { UserRoleEnum } from "@/enums/UserRoleEnum";
import { ProductReservationStatusEnum } from "@/enums/ProductReservationStatusEnum";

export default defineComponent({
  components: { DatePicker, Multiselect },
  props: ["form"],

  setup(props) {
    const eventOptions = ref([]);
    const productOptions = ref([]);
    const userOptions = ref([]);
    const { getLoggedInUser } = useLoggedInUser();
    const { model, errors } = toRefs(props.form.state);

    function onEventSelect(eventId: number) {
      const event = find(eventOptions.value, (event: any) => {
        return event.id === eventId;
      });

      Object.assign(model.value, {
        reserved_from: event.start_date,
        reserved_to: event.end_date,
      });
    }

    EventResource.query({ 'filters': { 'is_archived': 0 } }).then((response) => {
      eventOptions.value = response.data.data;
    });
    ProductResource.query().then((response) => {
      productOptions.value = response.data.data;
    });
    UserResource.query().then((response) => {
      userOptions.value = response.data.data;

      Object.assign(model.value, { user_id: getLoggedInUser().id });
    });

    return {
      eventOptions,
      productOptions,
      userOptions,
      onEventSelect,
      loggedInUser: getLoggedInUser(),
      UserRoleEnum,
      ProductReservationStatusEnum,
      model,
      errors,
    };
  },
});
